/**
 * This constant is used, to timeout refresh/authentication attempts if a refresh takes over 20 seconds it will be timed-out
 * And the user will have to login again
 *
 * This is also used at start of the application to check if the progress of authenticationInProgress or refreshingInProgress is true,
 * then we check after 20 seconds if they are still in progress if so, it's likely that we're stuck ( no other tab/application is refreshing/authenticating )
 * Therefore after 20 seconds we will clear this state, such that a user can try to login/refresh again.
 *
 * If we do get a signal from another tab that they have begun authenticating or refreshing, if after 20 seconds we never get a signal that they are done
 * we also clear the state, such that we are free to authenticate/refresh ourself again.
 *
 * - RefreshingInProgress blocks all http requests that are not related to the refreshing, and also blocks other tabs from doing the same.
 * - AuthenticationInProgress blocks other tabs from authenticating at the same time.
 */
export const authenticationTimeOutInMs = 20_000;
